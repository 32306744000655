<template>

    <div class="loading-animation" v-show="isShow">
      <div class="loading-item-bg">
        <div class="spinner"></div>
      </div>
    </div>

</template>

<script>

export default {
    props: {
        isShow: Boolean
    }
}

</script>

<style>  
.loading-animation {  
  /* 加载动画容器的样式 */  
  position: fixed;  
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0); /* 半透明黑色背景 */  
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 100000;
}

.loading-item-bg {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 80pt;
  width: 80pt;
  border-radius: 8pt;
  background-color: #00000060;
}
  
.spinner {  
  /* 这里是创建旋转动画的CSS */  
  border: 4px solid rgba(0, 0, 0, 0.1);  
  border-left-color: #fff;  
  border-radius: 50%;  
  width: 30px;  
  height: 30px;  
  animation: spin 1s linear infinite;  
}  
  
@keyframes spin {  
  0% { transform: rotate(0deg); }  
  100% { transform: rotate(360deg); }  
}  
</style>