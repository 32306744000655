<template>

    <div class="content-bg">
        <div style="display: flex;flex-direction: row;align-items: start;padding-top: 15pt;">
            <p> 提醒:复制保存本站链接,下次直接实用 </p>
            <div class="order-copy" @click="copyLink">
                <p style="color: white;">复制</p>
            </div>
        </div>
        <div class="content">
            <div class="content-item">
                <p style="color: black;text-align: start;margin-left: 5%;">订单号</p>
                <div class="order-input-bg">
                    <input class="order-input" type="text" placeholder="输入订单号" v-model="orderNoStr">
                </div>
                <div class="order-query-btn" @click="onTapQuery">
                    <p style="text-align: center;font-size: 15pt;color: white;">立即查询</p>
                </div>
            </div>
            <div class="content-item">
                <p style="margin-top: 1.5rem;font-size: 12pt;text-align: start;margin-left: 5%;">如何查找订单？</p>
                <p style="font-size: 12pt;text-align: start;margin-left: 5%;">进入支付宝-我一账单-账单详情</p>
                <div class="order-desc-img" style="margin-top: 5pt;"></div>
            </div>
            <p style="margin-bottom: 10pt;">温馨提示:系统保留近1年的订单，请慎重保存订单号,以便后期查询;</p>
        </div>
    </div>
    <XFToast ref="toast"></XFToast>
    <XFLoading></XFLoading>
</template>

<script>

import XFLoading from '../commons/XFLoading.vue'
import xf_network from '../network/xf_network';
import XFToast from '../utils/XFToast.vue'


export default {
    components: {
        XFLoading,
        XFToast
    },

    data() {
        return {
            orderNoStr: ""
        };
    },

    created() {
        document.title = "订单查询"
    },

    methods: {

        /// 点击查询
        onTapQuery() {
            if (this.orderNoStr.length <= 0) {
                this.showToast("请输入订单号")
                return
            }
            this.requestForOrderInfo(this.orderNoStr)
        },

        /// 查询订单信息
        async requestForOrderInfo(orderNo) {
            let res = await xf_network.post('/api/ttcs/orderState', { orderNo: orderNo })
            if (res.code == 0) {
                this.$router.push({
                    path: '/paySelect',
                    query: { orderNo }
                });
            } else {
                this.showToast(res.msg)
            }
        },

        /// 复制链接
        async copyLink() {
            const linkUrl = window.location.href
            if (navigator.clipboard && navigator.clipboard.writeText) {
                try {
                    await navigator.clipboard.writeText(linkUrl);
                    this.showToast("已经复制到粘贴板，建议保存起来，方便下次使用！")
                } catch (err) {
                    console.error('复制文本时出错：', err);
                    this.showToast("无法复制文本")
                }
            } else {
                console.error('Clipboard API 不支持当前浏览器。');
                // alert('你的浏览器不支持自动复制文本功能。');
                this.copyTextFallback(linkUrl)
            }
        },

        copyTextFallback(text) {
            const textArea = document.createElement('textarea');
            textArea.value = text;
            textArea.style.position = 'fixed';  // 防止出现滚动条
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            try {
                const successful = document.execCommand('copy');
                if (successful) {
                    this.showToast("已经复制到粘贴板，建议保存起来，方便下次使用！")
                } else {
                    this.showToast("复制失败")
                    throw new Error('复制失败');
                }
            } catch (err) {
                console.error('复制文本时出错：', err);
                this.showToast("复制失败")
            } finally {
                document.body.removeChild(textArea);
            }
        },

        showToast(message) {
            this.$refs.toast.show(message);
        },
    }
}

</script>

<style scoped>
.content {
    display: flex;
    flex-direction: column;
    width: 95%;
    align-items: start;
    padding-top: 15pt;
}

.content-bg {
    width: 100%;
    max-width: 500px;
    margin: auto;
    /* height: 100vh; */
    background-color: #F7CDA7;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.order-input-bg {
    height: 35pt;
    width: 90%;
    border-radius: 4pt;
    border: 1pt solid #BBBBBB;
    background-color: white;
    margin-top: 1rem;
    margin-left: auto;
    margin-right: auto;
}

.order-input {
    height: 95%;
    width: 95%;
    border: none;
    outline: none;
    margin: auto;
    font-size: 13pt;
}

.order-query-btn {
    display: flex;
    flex-direction: column;
    width: 90%;
    height: 35pt;
    margin-left: auto;
    margin-right: auto;
    margin-top: 15pt;
    border-radius: 4pt;
    background-color: rgb(98, 85, 25);
    cursor: pointer;
    justify-content: center;
}

.order-desc-img {
    background-image: url('~@/assets/bg_order_ali_pay.png');
    background-size: 100% 100%;
    width: 100%;
    background-repeat: no-repeat;
    aspect-ratio: 754 / 654.0;
}

.order-copy {
    display: flex;
    flex-direction: column;
    height: 20pt;
    width: 40pt;
    background-color: #6970E1;
    justify-content: center;
    align-items: center;
    border-radius: 4pt;
    cursor: pointer;
    margin-left: 1rem;
}

.content-item {
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 8pt;
    width: 100%;
    padding-top: 15pt;
    padding-bottom: 15pt;
    margin-bottom: 15pt;
    /* align-items: center; */
}
</style>