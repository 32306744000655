<template>
    <transition name="toast-fade">
      <div v-show="isVisible" class="toast-container">
        {{ message }}
      </div>
    </transition>
  </template>
   
  <script>
  export default {
    data() {
      return {
        isVisible: false,
        message: '',
        duration: 3000, // Toast显示的时间，单位毫秒
      };
    },
    methods: {
      show(message, duration = this.duration) {
        this.message = message;
        this.isVisible = true;
        setTimeout(() => {
          this.isVisible = false;
        }, duration);
      },
    },
  };
  </script>
   
  <style scoped>
  .toast-container {
    position: fixed;
    bottom: 50%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: white;
    padding: 10px;
    border-radius: 5px;
    z-index: 1000;
  }
   
  .toast-fade-enter-active, .toast-fade-leave-active {
    transition: opacity 0.3s;
  }
   
  .toast-fade-enter, .toast-fade-leave-to /* .fade-leave-active for <2.1.8 */ {
    opacity: 0;
  }
  </style>