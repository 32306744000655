import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../components/home/HomePage.vue';
import PaySelectPage from '../components/PaySelectPage.vue';
import OrderQuery from '@/components/home/OrderQuery.vue';

const routes = [
  {
    path: '/', 
    component: HomePage, 
  },
  {
    path: '/orderQuery', 
    component: OrderQuery, 
  },
  { path: '/paySelect', component: PaySelectPage },
];

const router = new createRouter({
  history: createWebHistory(), // 使用HTML5 History模式
  routes,
});

export default router;