import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import VueScrollPicker from "vue-scroll-picker";

import "vue-scroll-picker/lib/style.css";

const app = createApp(App)
app.use(router)
app.use(VueScrollPicker)
app.mount('#app')
