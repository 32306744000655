<template>
  <div style="margin-top: 10pt;margin-bottom: 10pt;">
    <div class="content" v-for="(item, index) in this.events" :key="index">
      <div class="ellipsis-container">
        <span class="dot" v-for="n in 3" :key="n"></span>
      </div>
      <div class="node-row">
        <div class="left">
          <p class="content-text" :style="{ backgroundColor: item.color }" v-show="index % 2 == 0">{{ item.title }}</p>
        </div>
        <div class="node-container">
          <div class="node">
            <div class="inner-circle"></div>
          </div>
        </div>
        <div class="right">
          <p class="content-text" :style="{ backgroundColor: item.color }" v-show="index % 2 != 0"> {{ item.title }} </p>
        </div>
      </div>
    </div>
    <div class="ellipsis-container">
        <span class="dot" v-for="n in 3" :key="n"></span>
      </div>
  </div>
</template>

<script>
export default {
  props: {
    events: Array
  },
};
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.ellipsis-container {
  display: flex;
  flex-direction: column;
  /* 使子元素垂直排列 */
  align-items: center;
  /* 使子元素在交叉轴上居中对齐 */
  width: 5pt;
  /* 容器的宽度，根据圆点大小调整 */
  margin: auto;
}

.dot {
  width: 50%;
  /* 圆点的宽度 */
  aspect-ratio: 1;
  border-radius: 50%;
  /* 圆点的边框半径，设置为50%使其成为圆形 */
  background-color: #808080;
  /* 使用当前文本颜色作为圆点颜色 */
  /* 圆点之间的垂直间距 */
  margin: 1.5pt 0;
}

/* 节点 */
.node-row {
  display: flex;
  /* 使用 Flexbox 布局 */
  /* flex-direction: row; */
  justify-content: space-between;
  align-items: center;
  width: 200pt;
}

.left,
.right {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.content-text {
  padding-left: 10pt;
  padding-right: 10pt;
  border-radius: 3pt;
  color: white;
}

.node-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20pt;
  height: 20pt;
  flex-shrink: 0;
}

.node {
  position: relative;
  width: 80%;
  /* 大圆的直径 */
  height: 80%;
  /* 大圆的直径 */
  border-radius: 50%;
  /* 使大圆成为圆形 */
  background: linear-gradient(145deg, #f6d365, #fda085);
  /* 大圆的渐变色背景 */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* 大圆的阴影效果 */
  overflow: hidden;
  /* 防止小圆超出大圆边界 */
  transition: transform 0.3s ease-in-out;
  /* 动画效果，用于 hover 时的缩放 */
}

.node:hover {
  transform: scale(1.1);
  /* hover 时大圆稍微放大 */
}

.inner-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  /* 小圆的直径 */
  height: 60%;
  /* 小圆的直径 */
  border-radius: 50%;
  /* 使小圆成为圆形 */
  background: radial-gradient(circle, #ffffff, #e6e6e6);
  /* 小圆的渐变色背景 */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  /* 小圆的阴影效果 */
  transition: transform 0.3s ease-in-out;
  /* 动画效果，用于 hover 时的缩放 */
}

.node:hover .inner-circle {
  transform: translate(-50%, -50%) scale(1.2);
  /* hover 时小圆放大 */
}
</style>