<template>
  <div class="ttcs-home">

    <!-- 顶部banner -->
    <div class="ttcs_bg">
      <!-- <div class="ttcs_top_item">
        <p style="font-size: 18pt;font-weight: 500;"> {{ getNowTimeStr() }} </p>
        <p style="font-size: 25pt;font-weight: 400; color: black;font-family: Monotype Corsiva;"> 八字测算 运势分析 </p>
      </div> -->
      <!-- <div class="top-flow-item"></div> -->
      <div class="floating-scale-animation-left">
        <transition name="zoom" @after-enter="nextImage(0)">
          <img style="width: 100%;height: 100%;" :src="leftImage" :key="currentImageIndex[0]" />
        </transition>
      </div>
      <div class="floating-scale-animation-right">
        <transition name="zoom" @after-enter="nextImage(1)">
          <img style="width: 100%;height: 100%;" :src="rightImage" :key="currentImageIndex[1]" />
        </transition>
        <!-- <img style="width: 100%;height: 100%;" :src="leftImage2" alt="运势测算"> -->
      </div>
    </div>

    <!-- 信息输入 -->
    <div class="ttcs_home_bg_3" ref="inputDiv">
      <div class="home-bg-top" style="margin-top: 10pt;">
        <h3 style="color: white;">立即测算流年运势</h3>
      </div>
      <div class="home-bg-3-out">
        <div class="home-bg-3-in">
          <div style="margin-top: 10pt;"></div>
          <div class="ttcs_input_item" :class="{ 'focused': isFocused }">
            <div class="input_text">
              您的姓名:
            </div>
            <input class="input-field" v-model="userName" @focus="isFocused = true" placeholder="输入姓名">
          </div>
          <div class="ttcs_input_item">
            <div class="input_text">您的性别:</div>
            <label for="male" style="font-size: 13pt;">男</label>
            <input type="radio" id="1" value="1" v-model="selectSex" />

            <label for="male" style="font-size: 13pt;">女</label>
            <input type="radio" id="0" value="0" v-model="selectSex" />
          </div>
          <div class="ttcs_input_item" @click="showDatePickerAction">
            <div class="input_text">出生日期:</div>
            <label style="font-size: 13pt; color: gray;">{{ selectedDateStr }}</label>
          </div>
          <div class="ttcs-ensure" ref="targetDiv" @click="onTapCommit">
            <p style="font-size: 15pt;color: white;">立即测算</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 解答你的人生各阶段困惑 -->
    <div class="ttcs_home_bg_3">
      <div class="home-bg-top" style="margin-top: 10pt;">
        <h3 style="color: white;">解答你的人生各阶段困惑</h3>
      </div>
      <div class="home-bg-3-out">
        <div class="home-bg-3-in">
          <div class="home-bg-1-content">
            <div class="home-bg-1-content-left"></div>
            <div class="home-bg-1-content-right">
              <div class="home-bg-1-content-right-title">
                <p>青年时期</p>
              </div>
              <p style="font-size: 10pt;">情路坎坷 何时才能找到真爱<br>职场不顺 升职加薪轮不到我</p>
              <div class="home-bg-1-content-right-title">
                <p>中年时期</p>
              </div>
              <p style="font-size: 10pt;">财运低迷 何时才能发财<br>无人相助 发展遭遇瓶颈</p>
              <div class="home-bg-1-content-right-title">
                <p>老年时期</p>
              </div>
              <p style="font-size: 10pt;">病气缠身 如何化解健康危机<br>孤苦伶仃 子女不够孝顺</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 揭晓你的先天命格大运 -->
    <div class="ttcs_home_bg_3">
      <div class="home-bg-top" style="margin-top: 10pt;">
        <h3 style="color: white;">揭晓你的先天命格大运</h3>
      </div>
      <div class="home-bg-3-out">
        <div class="home-bg-3-in">
          <p style="color: rgb(96, 0, 0);margin-top: 0.5rem;font-size: 12pt;font-weight: 420;">你先天是什么命格？注定富贵还是贫穷？</p>
          <div class="home-grid">
            <div class="home-grid-item" v-for="(title, index) in mgTitles" :key="index"
              :class="{ 'bordered': index === itemIndex }">
              <p class="home-grid-item-text">{{ title }}</p>
            </div>
            <div class="home-grid-center"></div>
          </div>
          <p style="color: rgb(96, 0, 0);margin-top: 12pt;font-size: 12pt;font-weight: 420;">命格不够好，更需把握一生大机遇</p>
          <div class="home-bg-3-mg"></div>
          <div class="home-bg-3-mg-bottom">
            <p class="home-bg-3-mg-bottom-text">你一生的大运走势如何？<br>有哪些关键时期和机遇要把握？<br>老师结合生辰八字，给你正确指引！</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 指引你一生运势发展 -->
    <div class="ttcs_home_bg_3">
      <div class="home-bg-top" style="margin-top: 10pt;">
        <h3 style="color: white;">指引你一生运势发展</h3>
      </div>
      <div class="home-bg-3-out">
        <div class="home-bg-3-in">
          <p style="color: rgb(96, 0, 0);margin-top: 12pt;font-size: 12pt;font-weight: 420;">详批一生运势，专业老师为你提供专属分析</p>
          <div class="home-bg-4-item" v-for="(item, index) in yyModels" :key="index">
            <div class="home-bg-4-item-left" :style="elementStyle(item.color)">
              <h1 style="color: white;">{{ item.name }}</h1>
            </div>
            <div class="home-bg-4-item-right">
              <p style="font-weight: 420;margin-bottom: 3pt;">{{ item.title }}</p>
              <div v-html="item.subTitle" style="font-size: 10pt;text-align: start;"></div>
            </div>
          </div>
          <div class="home-bg-3-mg-bottom">
            <p class="home-bg-3-mg-bottom-text">过去已成定局，你是否能把握未来<br>人生的转折机会？</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 找到你困境的突破口 -->
    <div class="ttcs_home_bg_3">
      <div class="home-bg-top" style="margin-top: 10pt;">
        <h3 style="color: white;">找到你困境的突破口</h3>
      </div>
      <div class="home-bg-3-out">
        <div class="home-bg-3-in">
          <div class="home-item-kj-top">
            <img class="home-item-kj-top-item" src="../../assets/ic_home_kj1.png" alt="运势测算">
            <img class="home-item-kj-top-item" src="../../assets/ic_home_kj2.png" alt="运势测算">
            <img class="home-item-kj-top-item" src="../../assets/ic_home_kj3.png" alt="运势测算">
          </div>
          <p style="margin-left: 15pt;margin-right: 15pt;font-size: 13pt;text-align: center;">
            逐一分析未来运势<br>找到困境突然口，看清感情婚姻 财运变化 事业发展趋势。助你抓住关键机遇，避开风险危机！</p>
          <div class="timeline">
            <TimeLineNode :events="this.timeNodes" />
          </div>
          <div class="home-bg-3-mg-bottom">
            <p class="home-bg-3-mg-bottom-text">把握关键抉择，突破现状困局</p>
          </div>
        </div>
      </div>
    </div>

    <!-- 找到你困境的突破口 -->
    <div class="ttcs_home_bg_3">
      <div class="home-bg-top" style="margin-top: 10pt;">
        <h3 style="color: white;">找到你困境的突破口</h3>
      </div>
      <div class="home-bg-3-out">
        <div class="home-bg-3-in">
          <div class="home-item-kj-top">
            <img class="home-item-bz-top-item" src="../../assets/ic_home_bz_1.png" alt="运势测算">
            <img class="home-item-bz-top-item" src="../../assets/ic_home_bz_2.png" alt="运势测算">
          </div>
          <p style="margin-left: 15pt;margin-right: 15pt;font-size: 12pt;">
            详批你的先天命格和大运情况<br>
            全方位解析你一生各项运势发展<br>
            剖析未来5年机遇和变动<br>
            老师定制开运攻略助你开启幸福人生</p>

          <img class="home-bottom-image" src="../../assets/ic_home_bottom.png" alt="运势测算">
        </div>
      </div>
    </div>

    <!-- 悬浮动画 -->
    <div v-if="showAnimation" class="floating-button" :class="{ 'show': showAnimation }" @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave" :style="{ bottom: bottomPosition + 'px' }" @click="onTapAnimateCommit">
      <p style="font-size: 15pt;color: white;">立即测算</p>
    </div>

    <!-- 底部公司信息 -->
    <div class="icp_bottom" ref="bottomICPDiv">
      <p style="font-size: 10pt;">联系我们：Ctrl_zqzl（微信）</p>
      <p style="font-size: 14pt;"> 武汉鑫新未来科技有限公司 </p>
      <a href="https://beian.miit.gov.cn" style="font-size: 11pt;color: blue;"> 鄂ICP备2024063541号-1 > </a>
    </div>
  </div>

  <!-- 顶部跑马灯 -->
  <div class="marquee-container">
    <p class="marquee-content" style="color: white;">
      {{ marqueeMsg }}
    </p>
  </div>
  <XFDatePicker :is-show="showDatePicker" @ensure="didSelectDate" @cancel="cancelDate"></XFDatePicker>
  <XFToast ref="toast"></XFToast>
  <XFLoading :is-show="isLoading"></XFLoading>
  <div class="stick-contact" @click="gotoOrderQuery">
    <p style="color: white;"> 订<br>单<br>查<br>询 </p>
  </div>

</template>

<script>

import network from "../network/xf_network.js"
import XFDatePicker from './XFDatePicker.vue'
import XFToast from '../utils/XFToast.vue'
import { handleWork } from '../utils/bazi.js'
import XFLoading from '../commons/XFLoading.vue'
import TimeLineNode from './TimeLineNode.vue'


export default {
  components: {
    XFDatePicker,
    XFToast,
    XFLoading,
    TimeLineNode
  },
  data() {
    return {
      model: null,
      isFocused: false, // 用于追踪输入框是否获得焦点
      userName: "",
      selectSex: 1,
      showDatePicker: false,
      selectDate: null,
      selectedDateStr: "选择日期", // 绑定的日期模型
      marqueeMsg: "我平台所有测试仅供娱乐，不得作为生活、工作之指导。我平台产品拒绝向未成年人提供服务，如未成年人使用且造成一切后果由其监护人自行承担。",
      isLoading: false,
      itemIndex: 1, // 命格选中
      mgTitles: [],
      yyModels: [],
      timeNodes: [],
      topImages: [],
      currentImageIndex: [0, 1],
      leftImage: require('../../assets/ic_home_top_1.png'),
      rightImage: require('../../assets/ic_home_top_2.png'),
      showAnimation: false,
      bottomPosition: 20, // 默认位置
    };
  },
  mounted() {
    // this.fetchData();
    this.startItemLoop(1);
    this.mgTitles = ['食神命格', '正财命格', '伤官命格', '偏财命格', '劫财命格', '劫财命格', '劫财命格', '七杀命格', '比肩命格', '正官命格', '正印命格', '偏印命格',]
    this.yyModels = [
      { name: '情', color: '#96000A', title: '一生姻缘分析', subTitle: '分析你的先天桃花姻缘<br>看你哪一年能脱单、结婚' },
      { name: '财', color: '#BFA15C', title: '一生财运分析', subTitle: '分析你的先天财富运势<br>哪一年是你的财运积累关键期<br>了解你的生财机遇和破财风险' },
      { name: '禄', color: '#104167', title: '一生事业分析', subTitle: '分析你的先天事业情况<br>找准你的事业上升关键期<br>了解适合你发展的行业有哪些' },
      { name: '寿', color: '#155849', title: '一生健康分析', subTitle: '分析你的先天健康情况<br>了解你的身体问题和未来变化<br>教你如何应对健康危机' },
      { name: '缘', color: '#97732B', title: '一生人脉分析', subTitle: '分析你的先天人脉机遇<br>了解你身边潜藏的贵人小人<br>在关键时期能否有贵人助力' },
    ]
    this.timeNodes = [
      { title: '职场陷阱', color: '#96000A', date: '2024' },
      { title: '寻觅良缘', color: '#BFA15C', date: '2024' },
      { title: '事业高升', color: '#104167', date: '2024' },
      { title: '破财风险', color: '#155849', date: '2024' },
      { title: '利于投资', color: '#97732B', date: '2024' },
    ]
    this.topImages = [
      require('../../assets/ic_home_top_1.png'),
      require('../../assets/ic_home_top_2.png'),
      require('../../assets/ic_home_top_3.png'),
      require('../../assets/ic_home_top_4.png'),
      require('../../assets/ic_home_top_5.png'),
    ]
    this.nextImage(0)
    this.nextImage(1)
    window.addEventListener("scroll", this.handleScroll);
  },

  methods: {

    elementStyle(color) {
      return {
        '--bg-color': color
      };
    },

    /// 命格跳转动画
    startItemLoop(index) {
      setTimeout(() => {
        this.itemIndex = index % 12 + 1;
        this.startItemLoop(index + 1)
      }, 500);
    },

    /// 跳转订单页面
    onTapCommit() {
      if (!this.checkInput()) {
        return;
      }
      this.startCeSuan();
    },

    onTapAnimateCommit() {
      if (!this.checkInput()) {
        const scrollY = this.$refs.inputDiv.offsetTop
        window.scrollTo({
          top: scrollY,
          behavior: 'smooth' // 平滑滚动
        });
        return;
      }
      this.startCeSuan();
    },

    async startCeSuan() {
      /// 测算八字
      var result = handleWork(
        this.selectDate.year,
        this.selectDate.month,
        this.selectDate.day,
        this.selectDate.hour
      );
      const bazi = result.bazi
      const zodiacs = result.shengxiao
      /// 转换时间戳
      let timeString = this.selectDate.year +
        "-" + String(this.selectDate.month).padStart(2, '0') +
        "-" + String(this.selectDate.day).padStart(2, '0');
      let birthDate = Date.parse(timeString)
      var netParam = { name: this.userName, sex: this.selectSex, birthDate: birthDate, bazi, zodiacs, price: 29.9 }
      this.isLoading = true
      var res = await network.post("/api/ttcs/createOrder", netParam)
      this.isLoading = false
      if (res.code == 0) {
        if (res.result != null) {
          let orderNo = res.result["orderNo"]
          this.$router.push({
            path: '/paySelect',
            query: { orderNo }
          });

        } else {
          this.showToast('服务器异常')
          console.log(res)
        }
      } else {
        this.showToast('服务器异常')
        console.log(res)
      }
    },

    checkInput() {
      if (this.userName.length <= 0) {
        this.showToast("请输入姓名")
        return false
      }
      if (this.selectDate == null) {
        this.showToast('请选择出生日期')
        return false
      }
      return true
    },

    showToast(message) {
      this.$refs.toast.show(message);
    },

    /// 选择日期
    showDatePickerAction() {
      this.showDatePicker = true
    },

    didSelectDate(date) {
      this.selectDate = date
      this.selectedDateStr = date.year + "年" + date.month + "月" + date.day + "日" + this.getHourStr(date.hour)
      this.showDatePicker = false
    },

    cancelDate() {
      this.showDatePicker = false
    },

    getHourStr(index) {
      const chineseHours = [
        "时辰未知",
        "子时",  // 23:00 - 01:00
        "丑时",  // 01:00 - 03:00
        "寅时",  // 03:00 - 05:00
        "卯时",  // 05:00 - 07:00
        "辰时",  // 07:00 - 09:00
        "巳时",  // 09:00 - 11:00
        "午时",  // 11:00 - 13:00
        "未时",  // 13:00 - 15:00
        "申时",  // 15:00 - 17:00
        "酉时",  // 17:00 - 19:00
        "戌时",  // 19:00 - 21:00
        "亥时"   // 21:00 - 23:00
      ];
      return chineseHours[index];
    },

    getNowTimeStr() {
      const date = new Date()
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0') // 月份从0开始，所以需要+1
      const day = date.getDate().toString().padStart(2, '0')
      return year + "-" + month + "-" + day

    },

    /// 查询订单
    gotoOrderQuery() {
      this.$router.push(
        { path: '/orderQuery', }
      )
    },

    nextImage(index) {
      this.currentImageIndex[index] = (this.currentImageIndex[index] + 1) % this.topImages.length;
      if (index == 0) {
        this.leftImage = this.topImages[this.currentImageIndex[index]];
      } else {
        this.rightImage = this.topImages[this.currentImageIndex[index]];
      }
    },

    // 悬浮按钮
    handleScroll() {
      const targetDiv = this.$refs.targetDiv;
      const targetDivOffsetTop = targetDiv.offsetTop + targetDiv.offsetHeight;
      if (window.scrollY - window.innerHeight > targetDivOffsetTop) {
        const bottomICPDiv = this.$refs.bottomICPDiv
        const offsetY = window.screenY - bottomICPDiv.offsetTop
        this.bottomPosition = 20;
        if (offsetY > 0) {
          this.bottomPosition = 20 + offsetY
        }
        this.showAnimation = true;
      } else {
        this.bottomPosition = 20;
        this.showAnimation = false;
      }
      console.log(this.$refs.bottomICPDiv.offsetTop)
    },
    handleMouseEnter() {
      this.showAnimation = true;
    },
    handleMouseLeave() {
      this.showAnimation = false;
    },
  },

  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
};


</script>

<style scoped>
@import './css/date_picker.css';
@import './css/home.css';
@import './css/animation.css';

.ttcs-home {
  max-width: 500px;
  /* 设置最大宽度 */
  margin: 0 auto;
  /* 居中显示 */
  padding: 0px;
  /* 内边距 */
  box-sizing: border-box;
  /* 边框计算在宽度内 */
  min-height: 100vh;
  /* 至少为视口高度 */
  /* background: #DDBA85; 背景颜色 */
  background-color: #F7CDA7;
  /* overflow: auto; */
  /* 内容超出时显示滚动条 */
  display: flex;
  flex-direction: column;
  position: relative;
}

.ttcs-home button {
  margin: 20px 0;
  padding: 10px 20px;
  font-size: 16px;
}

.ttcs_bg {
  background-image: url('~@/assets/bg_main.png');
  background-size: 100% 100%;
  /* 根据需要调整 */
  background-repeat: no-repeat;
  width: 100%;
  aspect-ratio: 1242/2208;
  display: flex;
}

.ttcs_top_item {
  position: relative;
  /* margin: auto; */
  margin-left: auto;
  margin-right: auto;
  /* padding-top: 20rem; */
  margin-top: 90%;
}


.ttcs_input_item {
  border-radius: 25px;
  border-width: 4px;
  border-color: #000000;
  border: 2px solid #ccc;
  background-color: #fcefca;
  width: 85%;
  aspect-ratio: 8.2;
  margin: 10px auto;
  display: flex;
  align-items: center;
}

.ttcs_input_item.focused {
  border-color: #42b983;
}

.input_text {
  font-size: 14pt;
  margin: 0px 15px;
  white-space: nowrap;
}

.input-field {
  /* flex-grow: 1; */
  padding: 5px;
  border: none;
  /* 输入框本身没有边框 */
  outline: none;
  /* 去除默认的聚焦轮廓 */
  background-color: transparent;
  font-size: 13pt;
  overflow: hidden;
  text-overflow: ellipsis;
  /* 显示省略号 */
  white-space: nowrap;
  /* 保持文本在一行显示 */
}

input[type="radio"] {
  margin-right: 10px;
  /* 右边距，根据需要调整 */
  margin-left: 3px;
}

.datepicker-container {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  /* 确保日期选择器在页面其他元素之上 */
}

.bg_home_5 {
  background-image: url('~@/assets/bg_home_5.png');
  background-size: 100% 100%;
  /* 背景图覆盖整个元素 */
  /* 根据需要调整 */
  background-repeat: no-repeat;
  /* background-position: center; */
  width: 100%;
  aspect-ratio: 750/224.0;
}

.ttcs-ensure {
  /* position: fixed; */
  background-image: url('~@/assets/ic_home_item_top.png');
  background-size: 100% 100%;
  width: 65%;
  height: 32pt;
  background-repeat: no-repeat;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10pt;
  border-radius: 5pt;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  animation: submit-animation 1.5s ease-in-out infinite;
}

/* 跑马灯 */
.marquee-container {
  max-width: 500px;
  position: absolute;
  width: 100%;
  left: 50%;
  top: 0;
  height: 2rem;
  background-color: #00000090;
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
  transform: translateX(-50%);
  line-height: 2rem;
}

.marquee-content {
  position: relative;
  display: inline-block;
  padding-left: 100%;
  /* 初始位置 */
  animation: marquee 12s linear infinite;
  /* 动画持续时间、类型和次数 */
}

@keyframes marquee {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(-100%);
    /* 滚动到最左边 */
  }
}

.icp_bottom {
  margin-top: 1rem;
  padding-bottom: 1rem;
  /* background-color: #F7CDA7;
  max-width: 500px;
  position: fixed;
  left: 50%;
  transform: translateX(-50%); */
}

.stick-contact {
  position: fixed;
  display: flex;
  flex-direction: column;
  bottom: 4rem;
  right: 0;
  /* transform: translateY(-50%); */
  z-index: 5;
  width: 25pt;
  padding-top: 10pt;
  padding-bottom: 10pt;
  border-radius: 4pt 0pt 0pt 4pt;
  background-color: #00000090;
  justify-content: center;
}
</style>
