<template>

    <div class="gearDate" v-if="isShow">
        <!-- 轮子的每个选项将在这里动态生成 -->
        <div class="bottom-dialog">
            <div class="picker-title">
                <div v-on:click="cancelAction" style="color: #999; font-size: 12pt;">取消</div>
                <div style="display: flex;flex-direction: column;">
                    <label style="color: blue; font-size: 13pt;">{{ selectYear }}年{{ selectMonth }}月{{ selectDay }}日
                        {{ getHourStr() }}</label>
                    <label style="color: blue; font-size: 11pt;">（农历：{{ lunarStr }}）</label>
                </div>
                <div v-on:click="ensureAction" style="color: blue; font-size: 12pt;">确定</div>
            </div>

            <div class="picker-scoll">
                <div class="picker-item">
                    <VueScrollPicker :options="years" :model-value="selectYear" @wheel="didSelectYear"
                        v-on:move="didSelectYear" v-on:end="didEndAction">
                    </VueScrollPicker>
                </div>
                <div class="picker-item">
                    <VueScrollPicker :options="months" :model-value="selectMonth" v-on:move="didSelectMonth"
                        @wheel="didSelectMonth" v-on:end="didEndAction">
                    </VueScrollPicker>
                </div>
                <div class="picker-item">
                    <VueScrollPicker :options="days" v-on:update:model-value="didSelectDay" v-on:move="didSelectDay"
                        @wheel="didSelectDay">
                    </VueScrollPicker>
                </div>
                <div class="picker-hour">
                    <VueScrollPicker :options="hours" :model-value="selectHour" v-on:move="didSelectHour"
                        @wheel="didSelectHour">
                    </VueScrollPicker>
                </div>
            </div>
        </div>
    </div>

</template>

<script>

import { VueScrollPicker } from 'vue-scroll-picker'
import { getLunar } from 'chinese-lunar-calendar';

export default {
    props: {
        isShow: Boolean,
        selectDate: {}
    },
    components: {
        VueScrollPicker, // 导出 VueScrollPicker 是组件
    },
    data() {
        return {
            showModal: false,
            years: [],
            months: [],
            days: [],
            hours: [
                { "value": 0, "name": "时辰未知" },
                { "value": 1, "name": "子时：23:00 - 01:00" },
                { "value": 2, "name": "丑时：01:00 - 03:00" },
                { "value": 3, "name": "寅时：03:00 - 05:00" },
                { "value": 4, "name": "卯时：05:00 - 07:00" },
                { "value": 5, "name": "辰时：07:00 - 09:00" },
                { "value": 6, "name": "巳时：09:00 - 11:00" },
                { "value": 7, "name": "午时：11:00 - 13:00" },
                { "value": 8, "name": "未时：13:00 - 15:00" },
                { "value": 9, "name": "申时：15:00 - 17:00" },
                { "value": 10, "name": "酉时：17:00 - 19:00" },
                { "value": 11, "name": "戌时：19:00 - 21:00" },
                { "value": 12, "name": "亥时：21:00 - 23:00" }
            ],
            selectYear: 1997,
            selectMonth: 7,
            selectDay: 1,
            selectHour: { "value": 0, "name": "时辰未知" },
            lunarStr: ""
        };
    },
    mounted() {
        this.initDates();
    },
    methods: {
        initDates() {
            if (this.selectDate != null) {
                this.selectYear = this.selectDate.year
                this.selectMonth = this.selectDate.month
                this.selectDay = this.selectDate.day
            }
            var startYear = 1950;
            var endYear = 2024;
            for (let year = startYear; year <= endYear; year++) {
                this.years.push(year)
            }
            for (let index = 1; index < 13; index++) {
                this.months.push(index.toString());
            }
            this.handleDays()
            this.handleLunar()
        },

        didSelectYear(value) {
            this.selectYear = value;
            this.handleLunar()
        },

        didSelectMonth(value) {
            this.selectMonth = value;
            this.handleLunar()
        },

        didSelectDay(value) {
            this.selectDay = value;
            this.handleLunar()
        },

        didSelectHour(value) {
            this.selectHour = this.hours[value];
        },

        /// 计算农历
        handleLunar() {
            const lunar = getLunar(this.selectYear, this.selectMonth, this.selectDay)
            this.lunarStr = lunar.lunarYear + lunar.dateStr
        },

        handleDays() {
            this.days = []
            const date = new Date(this.selectYear, this.selectMonth, 0); // 获取月份的最后一天
            var days = date.getDate()
            for (let index = 1; index <= days; index++) {
                this.days.push(index.toString())
            }
        },

        didEndAction() {
            this.handleDays()
        },

        ensureAction() {
            var date = {
                "year": this.selectYear,
                "month": this.selectMonth,
                "day": this.selectDay,
                "hour": this.selectHour.value,
            }
            this.$emit('ensure', date)
            // this.isShow = false
        },

        cancelAction() {
            this.$emit('cancel')
            // this.isShow = false
        },

        getHourStr() {
            var index = this.selectHour.value
            const chineseHours = [
                "时辰未知",
                "子时",  // 23:00 - 01:00
                "丑时",  // 01:00 - 03:00
                "寅时",  // 03:00 - 05:00
                "卯时",  // 05:00 - 07:00
                "辰时",  // 07:00 - 09:00
                "巳时",  // 09:00 - 11:00
                "午时",  // 11:00 - 13:00
                "未时",  // 13:00 - 15:00
                "申时",  // 15:00 - 17:00
                "酉时",  // 17:00 - 19:00
                "戌时",  // 19:00 - 21:00
                "亥时"   // 21:00 - 23:00
            ];
            return chineseHours[index];
        },
    }

}

</script>

<style scoped>
@import url('vue-scroll-picker/lib/style.css');

.bottom-dialog {
    background-color: white;
    height: 150pt;
    bottom: 0;
    max-width: 500px;
    width: 100%;
    left: 50%;
    /* 相对于包含块左边50%的位置 */
    transform: translateX(-50%);
    /* 向左移动自身宽度的50%来居中对齐 */
    position: absolute;
    border-top-left-radius: 8pt;
    border-top-right-radius: 8pt;
    /* 确保元素的宽度不会超出其包含块 */
    box-sizing: border-box;
}

.picker-title {
    margin: 12pt 0pt;
    padding-left: 20px;
    /* 左边距为20像素 */
    padding-right: 20px;
    /* 右边距为20像素 */
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.picker-scoll {
    display: flex;
    /* 开启Flexbox布局 */
    flex-direction: row;
    position: absolute;
    width: 100%;
    background-color: white;
}

.picker-item {
    width: 20%;
}

.picker-hour {
    width: 40%;
}
</style>